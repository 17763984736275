import { parseInt } from 'lodash'

const parseEnvInt = (key: string, def: number): number => {
  const text = process.env[key]
  if (!text) {
    return def
  }
  try {
    const asNumber = parseInt(text)
    return asNumber
  } catch (err) {}
  return def
}

export const AZURE = {
  clientId: process.env.AZURE_AD_CLIENT_ID ?? '',
  clientSecret: process.env.AZURE_AD_CLIENT_SECRET ?? '',
  tenantId: process.env.AZURE_AD_TENANT_ID ?? '',
  redirect_uri: `${process.env.NEXTAUTH_URL}/api/auth/callback/azure-ad`,
  scopes: {
    basic: ['email', 'openid', 'profile', 'offline_access'],
    custom: [
      'Chat.ReadBasic', // List chats in Dropdown
      'User.Read', // Sign in to the app and read signed-in user details
      'User.ReadBasic.All', // Get other user's profile photo
    ],
  },
  getScopes: () => [...AZURE.scopes.basic, ...AZURE.scopes.custom].join(' '),
}

export const APP_NAME = 'teams-reminder'
export const VERSION = process.env.VERSION ?? 'unknown'
export const DEVELOPMENT = process.env.NODE_ENV === 'development'
export const MONGO_URI = process.env.MONGODB_URI ?? ''
export const TASK_AUTH_SECRET = process.env.TASK_AUTH_SECRET || ''
export const DEV_EMAIL_SERVICE = process.env.SENDGRID_ACCESS_TOKEN === 'true'
export const SENDGRID_ACCESS_TOKEN = process.env.SENDGRID_ACCESS_TOKEN || ''
export const KEEP_ALIVE_REFRESH_TOKEN_INTERVAL = parseEnvInt(
  'KEEP_ALIVE_REFRESH_TOKEN_INTERVAL',
  5 * 60
) //5 min in secs
export const KEEP_ALIVE_REFRESH_TOKEN_LIMIT = parseEnvInt(
  'KEEP_ALIVE_REFRESH_TOKEN_LIMIT',
  10
) //10 users

const nextAuth = process.env.NEXTAUTH_URL || ''
export const SAFE_COOKIE = nextAuth.indexOf('https://') == 0
export const TEAMS_APP_ID = process.env.TEAMS_APP_ID ?? ''

export const TEAMS = {
  DEEP_LINK_DASHBOARD: `https://teams.microsoft.com/l/entity/${TEAMS_APP_ID}/com.teamsreminder.teams.main`,
}
